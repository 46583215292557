import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/rebateApply',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: 'api/rebateApply',
    method: 'put',
    data
  })
}

export function audit(data) {
  return request({
    url: 'api/rebateApply/check',
    method: 'put',
    data
  })
}

export function delay(data) {
  return request({
    url: 'api/rebateApply/delay',
    method: 'post',
    data
  })
}

export function getDistributorBalance(id) {
  return request({
    url: 'api/rebateRec/distributor/' + id,
    method: "get"
  })
}

export function getSupplierBalance(id) {
  return request({
    url: 'api/rebateRec/supplyer/' + id,
    method: "get"
  })
}

export function getMaxRebateByPurchaseOrder(id) {
  return request({
    url: 'api/rebateRec/orderform/' + id + "/info",
    method: "get"
  })
}