<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="700px" @closed="form = null; auditForm = null;">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
          <div class="h sb">
            <el-form-item prop="_ratio" label="类型">
              <el-radio-group v-model="form._ratio" @change="handleRatioChange">
                <el-radio-button :label="1">收款</el-radio-button>
                <el-radio-button :label="-1">退款</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="relationFormCode" label="销售单" label-width="116px" v-if="form.relationFormType === 0">
              <datagrid-picker v-model="form.relationFormCode" value-field="formCode" display-field="formCode" url="api/orderForm" :params="{notStatus: 0, payStatusList: [0,1,2,4], sort: 'formCode,desc'}" placeholder="请选择销售单" @change="handleWholesaleOrderChange" style="width: 230px;">
                <el-table-column prop="formCode" label="销售单号" width="150" />
                <el-table-column label="总金额" width="120" align="right" :formatter="r => {return '￥' + ((r.totalMoney-(r.discountAmount || 0)) / 100).toFixed(2);}" />
                <el-table-column label="支付方式" width="120" :formatter="r => {return paymentMethods[r.payType];}" />
                <el-table-column prop="buyerName" label="经销商" width="180" />
                <el-table-column prop="purchaseTime" label="下单时间" width="150" :formatter="r => { return new Date(r.purchaseTime).format(); }" />
              </datagrid-picker>
            </el-form-item>
          </div>
          <template v-if="form.relationFormType === 0">
            <div class="h c">
              <el-form-item label="经销商">
                <el-input v-model="form.friendEntName" placeholder="请选择销售单" readonly style="width: 230px;" />
              </el-form-item>
              <el-form-item label="支付方式" label-width="116px">
                <el-input :value="form.payType != null ? paymentMethods[form.payType] : ''" placeholder="请选择销售单" readonly style="width: 230px;" />
              </el-form-item>
            </div>
            <el-form-item :label="form._ratio > 0 ? '收款日期' : '退款日期'" prop="payTime">
              <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
            </el-form-item>
            <el-form-item prop="payMoney" :label="form._ratio > 0 ? '收款金额' : '退款金额'">
              <div class="h c" v-if="form.relationFormCode">
                <price-input v-model="form.payMoney" :min="0" :max="999999999" :step-ratio="10000" style="width: 230px" />
                <div class="padding-0-10" v-if="form._ratio > 0">该销售单还需要收款{{$price(form._maxPayMoney - (form.payRebate || 0))}}</div>
                <div class="padding-0-10" v-else>该销售单可退款{{$price(form._maxRefundMoney)}}</div>
              </div>
              <el-input placeholder="请选择销售单" readonly v-else style="width: 230px;" />
            </el-form-item>
            <template v-if="form._ratio > 0">
              <el-form-item key="rebate" label="使用返利" prop="payRebate">
                <div class="h c" v-if="form.relationFormCode">
                  <price-input v-model="form.payRebate" :min="0" :max="maxRebate" style="width: 230px;" />
                  <div class="padding-0-10">最高可使用{{$price(maxRebate * 100)}}的返利</div>
                </div>
                <el-input placeholder="请选择销售单" readonly v-else style="width: 230px;" />
              </el-form-item>
              <el-form-item key="inpay" prop="payerAccount" label="支付账户">
                <div class="h">
                  <el-input v-model="form.payerAccount" maxlength="80" class="flex" />
                  <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.friendEntId,sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" v-if="form.friendEntId" style="margin-left: 10px;">
                    <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                    <el-table-column prop="accountCode" label="账号" />
                    <el-table-column prop="accountName" label="账户" />
                    <el-table-column prop="bankName" label="银行名称" />
                  </datagrid-picker>
                </div>
              </el-form-item>
              <el-form-item key="inrec" prop="payeeAccount" label="收款账户">
                <div class="h">
                  <el-input v-model="form.payeeAccount" maxlength="80" class="flex" />
                  <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" style="margin-left: 10px;">
                    <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                    <el-table-column prop="accountCode" label="账号" />
                    <el-table-column prop="accountName" label="账户" />
                    <el-table-column prop="bankName" label="银行名称" />
                  </datagrid-picker>
                </div>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item key="outpay" prop="payerAccount" label="支付账户">
                <div class="h">
                  <el-input v-model="form.payerAccount" maxlength="80" class="flex" />
                  <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" v-if="form.friendEntId" style="margin-left: 10px;">
                    <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                    <el-table-column prop="accountCode" label="账号" />
                    <el-table-column prop="accountName" label="账户" />
                    <el-table-column prop="bankName" label="银行名称" />
                  </datagrid-picker>
                </div>
              </el-form-item>
              <el-form-item key="outrec" prop="payeeAccount" label="收款账户">
                <div class="h">
                  <el-input v-model="form.payeeAccount" maxlength="80" class="flex" />
                  <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.friendEntId,sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" style="margin-left: 10px;">
                    <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                    <el-table-column prop="accountCode" label="账号" />
                    <el-table-column prop="accountName" label="账户" />
                    <el-table-column prop="bankName" label="银行名称" />
                  </datagrid-picker>
                </div>
              </el-form-item>
            </template>
          </template>
          <template v-else>
            <div class="h">
              <el-form-item prop="friendEntId" label="经销商">
                <quick-select v-model="form.friendEntId" filterable :label.sync="form.friendEntName" value-field="enterpriseId" url="api/distributor" placeholder="请选择经销商" style="width: 230px;" />
              </el-form-item>
              <el-form-item label="支付方式" prop="payType" label-width="116px">
                <el-select v-model="form.payType" filterable style="width: 230px">
                  <el-option v-for="(value,key) in paymentMethods" :key="key" :label="value" :value="key" />
                </el-select>
              </el-form-item>
            </div>
            <div class="h">
              <el-form-item prop="payMoney" :label="form._ratio > 0 ? '收款金额' : '退款金额'">
                <price-input v-model="form.payMoney" :min="0" :max="999999999" :step-ratio="10000" style="width: 230px" />
              </el-form-item>
              <el-form-item :label="form._ratio > 0 ? '收款日期' : '退款日期'" prop="payTime" label-width="116px">
                <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
              </el-form-item>
            </div>
          </template>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" :rows="3" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button type="primary" size="mini" :loading="saving" @click="doSubmit">提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-position="right" label-suffix=":">
          <form-info-item label="经销商">{{form.friendEntName}}</form-info-item>
          <!-- <form-info-item label="收款依据">{{relationFormTypes[form.relationFormType]}}</form-info-item> -->
          <form-info-item label="销售单号" v-if="form.relationFormType === 0">{{form.relationFormCode}}</form-info-item>
          <form-info-item label="类型">{{form._ratio > 0 ? '收款' : '退款'}}</form-info-item>
          <form-info-item label="支付方式">{{paymentMethods[form.payType]}}</form-info-item>
          <form-info-item :label="form._ratio > 0 ? '收款日期' : '退款日期'">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
          <form-info-item :label="form._ratio > 0 ? '收款金额' : '退款金额'">
            <b :class="form._ratio > 0 ? '' : 'fc-e'">{{$price(form.payMoney)}}</b>
          </form-info-item>
          <form-info-item label="使用返利" v-if="form.relationFormType === 0">{{$price(form.payRebate)}}</form-info-item>
          <form-info-item label="入账日期" v-if="form.status === 1">{{new Date(form.checkinTime).format('yyyy/MM/dd')}}</form-info-item>
          <template v-if="form.relationFormType === 0">
            <form-info-item label="支付账户">{{form.payerAccount}}</form-info-item>
            <form-info-item label="收款账户">{{form.payeeAccount}}</form-info-item>
          </template>
          <form-info-item label="备注">{{form.info}}</form-info-item>
          <template v-if="!auditable">
            <el-divider />
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <form-info-item label="审批意见" v-if="form.checkInfo">{{form.checkInfo}}</form-info-item>
          </template>
        </el-form>
        <template v-if="auditable && auditForm">
          <el-divider content-position="left">审批</el-divider>
          <el-form ref="form" :model="auditForm" label-width="78px" label-position="right" label-suffix=":">
            <div class="h">
              <el-form-item label="是否通过" style="width: 50%;">
                <el-radio-group v-model="auditForm.pass" @change="handleAuditPassChange">
                  <el-radio-button :label="true">通过</el-radio-button>
                  <el-radio-button :label="false">不通过</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="入账日期" class="flex" v-if="auditForm.pass">
                <el-date-picker type="date" v-model="auditForm.checkinTime" value-format="timestamp" :clearable="false" style="width:100%;" />
              </el-form-item>
            </div>
            <el-form-item label="审批意见">
              <el-input type="textarea" v-model="auditForm.checkInfo" :maxlength="120" :rows="3" resize="none" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="auditing" @click="doAudit">提交审批</el-button>
              <el-button @click="dialog = false">取消</el-button>
            </el-form-item>
          </el-form>
        </template>
      </template>
    </template>
  </el-dialog>
</template>
      
      
<script>
import { add, edit, audit } from "@/api/payForm";
import { getMaxRebateByPurchaseOrder } from "@/api/rebate";

export default {
  data() {
    return {
      saving: false,
      auditing: false,
      dialog: false,
      auditable: false,
      form: null,
      auditForm: null,
      maxRebate: 0,
      friendEntTypes: ["经销商", "门店"],
      paymentMethods: ["在线支付", "线下支付"],
      paymentAccoutTypes: ["银联", "支付宝", "微信", "银行卡"],
      formTypes: ["银行卡", "支付宝", "微信"],
      relationFormTypes: ["销售单收款", "其他"],
      friendEntUrl: ["api/distributor", "api/shop"],
      rules: {
        relationFormCode: [{ required: true, message: "请选择销售单" }],
        friendEntId: [{ required: true, message: "请选择经销商" }],
        payType: [{ required: true, message: "请选择支付方式" }],
        payTime: [{ required: true, message: "请选择收款日期" }],
        payMoney: [{ required: true, message: "请填写收款金额" }],
        payerAccount: [{ required: true, message: "请填写支付方的账户" }],
        payeeAccount: [{ required: true, message: "请填写收款方的账户" }]
      },
      status: [
        {
          type: "info",
          label: "待审批"
        },
        {
          type: "success",
          label: "已通过"
        },
        {
          type: "danger",
          label: "未通过"
        }
      ]
    };
  },
  computed: {
    title() {
      let str = "";
      if (this.form) {
        if (this.editable) {
          if (this.form.id) {
            str = "修改{0}";
          } else {
            str = "新增{0}";
          }
        } else if (this.auditable) {
          str = "审批{0}";
        } else {
          str = "{0}详情";
        }
        str = str.replace(
          "{0}",
          this.form.relationFormType === 0 ? "收/退款信息" : "费用单"
        );
      }
      return str;
    },
    editable() {
      return this.form && !this.form.id && !this.auditable;
    }
  },
  methods: {
    handleRatioChange() {
      if (this.form._ratio > 0) {
        this.form.payMoney = this.form._maxPayMoney - this.form.payRebate;
      } else {
        this.form.payMoney = this.form._maxRefundMoney;
      }
    },
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.$confirm(
            "收款信息提交后将不能修改，请确认所填信息无误。<br />确定要进行提交吗？",
            "操作确认",
            {
              type: "warning",
              dangerouslyUseHTMLString: true
            }
          ).then(_ => {
            this.saving = true;
            let form = JSON.parse(JSON.stringify(this.form));
            form.inOutType = form._ratio > 0 ? 0 : 1;
            form.payMoney *= form._ratio;
            add(form)
              .then(res => {
                this.$notify({
                  title: "添加收款信息成功",
                  type: "success",
                  duration: 2500
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally(_ => {
                this.saving = false;
              });
          });
        });
    },
    doAudit() {
      this.$confirm(
        `确定对该收款信息进行【${
          this.auditForm.pass ? "通过" : "不通过"
        }】审批吗？`,
        "操作提示",
        {
          type: "warning"
        }
      ).then(_ => {
        this.auditing = true;
        audit(this.auditForm)
          .then(res => {
            this.$notify({
              title: "审批成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.auditing = false;
          });
      });
    },
    resetForm(form, type = 0, auditable = false) {
      this.auditable = form && form.id && auditable;
      if (form) {
        form._ratio = form.payMoney >= 0 ? 1 : -1;
      }
      this.form = form || {
        _ratio: 1,
        code: "",
        friendEntType: 0,
        friendEntId: null,
        friendEntName: "",
        payType: null,
        payerAccount: "",
        payTime: new Date().getTime(),
        payeeAccountType: 0,
        payMoney: 0,
        payRebate: 0,
        rebateMoney: 0,
        payeeAccount: "",
        relationFormType: type,
        relationFormId: "",
        relationFormCode: "",
        info: "",
        status: 0
      };
      if (this.auditable) {
        this.auditForm = {
          id: form.id,
          pass: true,
          checkinTime: this.form.payTime,
          checkInfo: ""
        };
      }
      this.dialog = true;
    },

    loadMaxRebate(id, init) {
      getMaxRebateByPurchaseOrder(id).then(res => {
        this.form._maxPayMoney =
          res.orderTotleMoney - res.usedRebate - res.useMoney;
        this.form._maxRefundMoney = res.realPayMoney || 0;
        res = res || 0;
        if (!init) {
          let max = res.maxCanUseRebate || 0;
          if (max > this.form._maxPayMoney) max = this.form._maxPayMoney;
          this.form.payRebate = max;
          this.maxRebate = max / 100;
          this.handleRatioChange();
        }
      });
    },

    handleAuditPassChange() {
      if (this.form && this.auditForm) {
        this.auditForm.checkinTime = this.auditForm.pass
          ? this.form.payTime
          : null;
      }
    },
    handleRelationChange() {
      this.maxRebate = 0;
      this.form.payRebate = 0;
      this.form.relationFormId = "";
      this.form.relationFormCode = "";
    },
    handleWholesaleOrderChange(a, b, row, init) {
      if (row) {
        this.form.relationFormId = row.id;
        this.form.friendEntId = row.buyerId;
        this.form.friendEntName = row.buyerName;
        this.form.payType = row.payType;
        if (!init) this.form.payMoney = row.totalMoney-(row.discountAmount||0);
        this.loadMaxRebate(row.id, init);
      }
    },
    handlePayerAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payerAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payerAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    },

    handlePayeeAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payeeAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payeeAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    }
  }
};
</script>