import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/payForm',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: 'api/payForm',
    method: 'put',
    data
  })
}

export function audit(data) {
  return request({
    url: 'api/payForm/check',
    method: "post",
    data
  })
}

export function pay(data) {
  return request({
    url: 'api/payForm/pay',
    method: "post",
    data
  })
}

export function credit(data) {
  return request({
    url: 'api/payForm/buyer',
    method: "post",
    data
  })
}